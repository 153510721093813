import { gql } from "@apollo/client";
import {
  CohortMeetingsCard_CohortFragment,
  VideoProvider,
} from "@generated/graphql";
import { VideoProviderBadge } from "@shared/Badges/VideoProviderBadge";
import { CohortsScheduleCalendarModal } from "components/cohorts/CohortsScheduleCalendar";
import { ICalendarGeneratorPopover } from "components/iCalendar/ICalendarGeneratorPopover";
import { Button, Icon, Tooltip } from "components/shared";
import {
  Card,
  CardDetailsRow,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { useMemo, useState } from "react";
import { RoomMeetingLink } from "./components/RoomMeetingLink";

CohortMeetingsCard.fragments = {
  cohort: gql`
    fragment CohortMeetingsCard_Cohort on Cohort {
      id
      meetingRoom
      hostMeetingLink
      studentMeetingLink
      engagement {
        videoProvider
      }
    }
  `,
};

type Props = {
  className?: string;
  cohort: CohortMeetingsCard_CohortFragment;
};

export function CohortMeetingsCard({
  className,
  cohort: {
    id,
    meetingRoom,
    hostMeetingLink,
    studentMeetingLink,
    engagement: { videoProvider },
  },
}: Props) {
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const meetingLinkRows: CardDetailsRow[] = useMemo(() => {
    const isZoomProvider = videoProvider === VideoProvider.Zoom;
    const isManualProvider = videoProvider === VideoProvider.Manual;

    return [
      [
        "Video Provider",
        <VideoProviderBadge key={0} provider={videoProvider} />,
      ],
      isManualProvider
        ? []
        : [
            "Meeting Links",
            meetingRoom ? (
              <div key={0} className="flex flex-col w-fit gap-y-1">
                <RoomMeetingLink
                  cohortId={id}
                  isZoomHostLink={true}
                  videoProvider={videoProvider}
                  meetingLinkName={
                    isZoomProvider ? "Host Link" : "Meeting Link"
                  }
                  meetingLink={studentMeetingLink ?? ""}
                />
                {isZoomProvider && (
                  <RoomMeetingLink
                    cohortId={id}
                    isZoomHostLink={false}
                    meetingLinkName="Student Link"
                    meetingLink={hostMeetingLink ?? ""}
                    videoProvider={videoProvider}
                  />
                )}
              </div>
            ) : (
              <Tooltip
                className="flex items-center"
                content={
                  <div className="w-[200px] text-center gap-x-1">
                    Contact technical support if the meeting is not available
                    within 3 minutes of cohort creation
                  </div>
                }
              >
                Meeting has not been created yet.
                <Icon icon="infoCircle" size={4} className="pb-1" />
              </Tooltip>
            ),
          ],
      [
        "Schedule",
        <div
          key={0}
          className="flex flex-row items-center gap-x-1 gap-y-2 text-gray-900"
        >
          <Button
            height="xs"
            theme="tertiary"
            className="flex items-center text-sm font-medium shadow-none border border-gray-300"
            onClick={() => setShowScheduleModal(true)}
          >
            <Icon
              icon="calendarEvents"
              color="text-slate-600"
              size={4}
              button
            />
            Show
          </Button>
          <ICalendarGeneratorPopover
            buttonHeight="xs"
            openOrientation="BOTTOM"
            buttonClassName="text-base font-medium shadow-none border border-gray-300"
            initialBuildICalendarInput={{ cohortIds: [id] }}
          />
        </div>,
      ],
    ] as CardDetailsRow[];
  }, [hostMeetingLink, id, meetingRoom, studentMeetingLink, videoProvider]);

  return (
    <>
      <Card
        icon="video"
        header="Meetings"
        className={className}
        rows={meetingLinkRows}
        heightMode={HeightMode.Fit}
        headerOverlayColor={HeaderColors.Teal}
        rowStyles={[{ minWidth: "130px" }, {}]}
      />

      <CohortsScheduleCalendarModal
        cohortIds={[id]}
        show={showScheduleModal}
        closeModal={() => setShowScheduleModal(false)}
      />
    </>
  );
}
