import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  RegenerateCohortMeetingMutation,
  RegenerateCohortMeetingMutationVariables,
} from "@generated/graphql";
import { Modal } from "components/shared";
import {
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared/Toast";

const REGENERATE_COHORT_MEETING = gql`
  mutation RegenerateCohortMeeting($cohortId: ID!) {
    regenerateCohortMeeting(cohortId: $cohortId) {
      id
    }
  }
`;
type Props = {
  show: boolean;
  cohortId: string;
  onClose: () => void;
};

export function MSTeamsMeetingRegenerationModal({
  show,
  cohortId,
  onClose,
}: Props) {
  const [regenerateCohortMeeting, { loading }] = useMutation<
    RegenerateCohortMeetingMutation,
    RegenerateCohortMeetingMutationVariables
  >(REGENERATE_COHORT_MEETING, {
    onError: (error: ApolloError) =>
      triggerErrorToast({
        message: "Failed to regenerate meeting.",
        sub: error.message,
      }),
    onCompleted: () => {
      triggerSuccessToast({
        message: "Meeting will regenerate within 3 minutes!",
      });
      onClose();
    },
  });

  return (
    <Modal
      show={show}
      title="Regenerate Meeting"
      onClose={onClose}
      onDismissClick={onClose}
    >
      <div className="p-4">
        <p className="text-sm">
          If you are not seeing accurate live participants for this cohort,
          regenerating the meeting may help. Make sure no one is in the meeting
          before regenerating.
        </p>
      </div>

      <Modal.Buttons>
        <Modal.Button
          type="delete"
          loading={loading}
          onClick={() =>
            regenerateCohortMeeting({
              variables: { cohortId },
              refetchQueries: ["CohortDetailsPage"],
            })
          }
        >
          Confirm
        </Modal.Button>

        <Modal.Button type="cancel" onClick={onClose}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </Modal>
  );
}
