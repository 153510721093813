import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import clsx from "clsx";
import { isSameDay, isSameMonth } from "date-fns";
import dayjs from "dayjs";

export const normalize = (dates: string[] | undefined) =>
  (dates ?? []).map((d) => normalizeDateFromUTCDateTime(new Date(d)));

export const specialDaysCellRenderer = (
  current: dayjs.Dayjs,
  month: dayjs.Dayjs,
  updatedPrimarySpecialDays: Date[],
  secondarySpecialDays: Date[]
) => {
  const isToday = isSameDay(new Date(), current.toDate());
  const isPrimarySpecialDay = updatedPrimarySpecialDays.some((SpecialDate) =>
    isSameDay(new Date(SpecialDate), current.toDate())
  );

  const isSecondarySpecialDay = secondarySpecialDays.some((SpecialDate) =>
    isSameDay(new Date(SpecialDate), current.toDate())
  );

  const isSecondarySpecialFromOtherMonth =
    isSecondarySpecialDay && !isSameMonth(current.toDate(), month.toDate());

  const isPrimarySpecialFromOtherMonth =
    isPrimarySpecialDay && !isSameMonth(current.toDate(), month.toDate());

  const isPrimaryAndSecondary = isSecondarySpecialDay && isPrimarySpecialDay;

  const isPrimaryAndSecondaryOtherMonth =
    isSecondarySpecialFromOtherMonth && isPrimarySpecialFromOtherMonth;

  return (
    <div
      className={clsx(
        isSecondarySpecialDay && "rounded-xl mx-[6px] text-white bg-purple-500",
        isPrimarySpecialDay && "rounded-xl mx-[6px] text-white bg-blue-500",
        isSecondarySpecialFromOtherMonth && "bg-purple-200",
        isPrimarySpecialFromOtherMonth && "bg-blue-200",
        isToday && "rounded-xl mx-[6px] text-white bg-red-500",
        isToday &&
          isSecondarySpecialDay &&
          "bg-linear-to-b from-red-500 to-purple-500",
        isToday &&
          isPrimarySpecialDay &&
          "bg-linear-to-b from-red-500 to-blue-500",
        isPrimaryAndSecondary && "bg-linear-to-b from-blue-500 to-purple-500",
        isPrimaryAndSecondaryOtherMonth &&
          "bg-linear-to-t from-blue-300 to-purple-200",
        isToday &&
          isPrimaryAndSecondary &&
          "bg-linear-to-b from-blue-500 via-red-500 to-purple-500"
      )}
    >
      {current.date()}
    </div>
  );
};
