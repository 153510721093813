import { gql } from "@apollo/client";
import { CohortLogsCard_CohortFragment } from "@generated/graphql";
import { CreatorUpdaterLabel } from "components/shared";
import {
  Card,
  getCollapseHeader,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { ReactNode, useState } from "react";

CohortLogsCard.fragments = {
  cohort: gql`
    fragment CohortLogsCard_Cohort on Cohort {
      id
      createdAt
      createdBy {
        id
        ...CreatorUpdaterLabel_User
      }
      updatedAt
      updatedBy {
        id
        ...CreatorUpdaterLabel_User
      }
    }
    ${CreatorUpdaterLabel.fragments.user}
  `,
};

type Props = { className?: string; cohort: CohortLogsCard_CohortFragment };

export function CohortLogsCard({ cohort, className }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { updatedBy, createdBy, updatedAt, createdAt } = cohort;

  let updatedAtVal: ReactNode = "--";
  let updatedByVal: ReactNode = "--";
  if (Math.abs(updatedAt - createdAt) > 3 * 1000) {
    updatedAtVal = new Date(updatedAt).toLocaleString();
    updatedByVal = <CreatorUpdaterLabel user={updatedBy} />;
  }

  const getSpan = (label: string) => (
    <span className="flex whitespace-nowrap">{label}</span>
  );

  return (
    <Card
      icon="terminal"
      className={className}
      isCollapsed={isCollapsed}
      heightMode={HeightMode.Fit}
      headerOverlayColor={HeaderColors.Black}
      header={getCollapseHeader("Logs", isCollapsed, setIsCollapsed)}
      rows={
        isCollapsed
          ? []
          : [
              [
                getSpan("Created At"),
                `${new Date(createdAt).toLocaleString()}`,
              ],
              [
                getSpan("Created By"),
                <CreatorUpdaterLabel key={0} user={createdBy} />,
              ],
              [getSpan("Updated At"), updatedAtVal],
              [getSpan("Updated By"), updatedByVal],
            ]
      }
    />
  );
}
