import { gql, useQuery } from "@apollo/client";
import { useUIState } from "@contexts/UIStateProvider";
import {
  CohortInstructionalPlanCard_CohortFragment,
  CohortInstructionalPlanCard_CohortStrandStandardFragment,
  CohortInstructionalPlanCardQuery,
  CohortInstructionalPlanCardQueryVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { Card, Icon, Table } from "components/shared";
import { HeaderColors } from "components/shared/Card";
import { getAddModalOpenHeader } from "components/shared/Card/helpers";
import { useAuth } from "contexts/AuthProvider";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { useEffect, useState } from "react";
import { CellProps, Column } from "react-table";
import {
  AddCohortStrandStandard,
  STANDARDS_SEARCH,
  STRANDS_SEARCH,
} from "./components/AddCohortStrandStandard";
import { DeleteCohortStrandStandards } from "./components/DeleteCohortStrandStandards";
import { StandardsStrandsList } from "./components/StandardsStrandsList";

const REFETCH_QUERIES = [STANDARDS_SEARCH, STRANDS_SEARCH];

CohortInstructionalPlanCard.fragments = {
  cohortStrandStandard: gql`
    fragment CohortInstructionalPlanCard_CohortStrandStandard on CohortStrandStandard {
      id
      fromDate
      toDate
      strands {
        id
        key
        description
        url
        notes
      }
      standards {
        id
        key
        description
        url
        notes
      }
      ...DeleteCohortStrandStandards_CohortStrandStandard
      ...StandardsStrandsList_CohortStrandStandard
    }
    ${DeleteCohortStrandStandards.fragments.cohortStrandStandard}
    ${StandardsStrandsList.fragments.cohortStandardStrand}
  `,
  cohort: gql`
    fragment CohortInstructionalPlanCard_Cohort on Cohort {
      id
      endDate
      startDate
    }
  `,
};

const GET_COHORT_INSTRUCTIONAL_PLAN_CARD = gql`
  query CohortInstructionalPlanCard($cohortId: ID!) {
    getCohortStrandStandards(cohortId: $cohortId) {
      ...CohortInstructionalPlanCard_CohortStrandStandard
    }
  }
  ${CohortInstructionalPlanCard.fragments.cohortStrandStandard}
`;

type Props = {
  readOnly?: boolean;
  cohort: CohortInstructionalPlanCard_CohortFragment;
};

export function CohortInstructionalPlanCard({
  readOnly,
  cohort: { id, startDate, endDate },
}: Props) {
  const { isAdmin } = useAuth();
  const { storeBoolean } = useUIState();
  const cardKey = `CohortInstructionalPlanCard:${id}`;
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [period, setPeriod] =
    useState<CohortInstructionalPlanCard_CohortStrandStandardFragment | null>(
      null
    );

  const { data, loading } = useQuery<
    CohortInstructionalPlanCardQuery,
    CohortInstructionalPlanCardQueryVariables
  >(GET_COHORT_INSTRUCTIONAL_PLAN_CARD, {
    variables: { cohortId: id },
    onError: (err) => fetchErrToast("Instructional Plan", err),
  });

  const openDeleteModal = (
    item: CohortInstructionalPlanCard_CohortStrandStandardFragment
  ) => {
    setPeriod(item);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setPeriod(null);
  };

  const hasInstructionPlan = data?.getCohortStrandStandards.length !== 0;

  useEffect(() => {
    storeBoolean(cardKey, hasInstructionPlan || isAdmin);
  }, [hasInstructionPlan, storeBoolean, id, cardKey, isAdmin]);

  return (
    <>
      {!loading && (hasInstructionPlan || isAdmin) ? (
        <>
          <Card
            id={cardKey}
            icon="strand"
            headerOverlayColor={HeaderColors.Emerald}
            header={
              readOnly
                ? "Instructional Plan"
                : getAddModalOpenHeader(
                    "Instructional Plan",
                    () => isAdmin && setShowAddModal(true)
                  )
            }
          >
            <Table
              pageSize={100}
              loading={loading}
              showPagination={false}
              cellPadding="px-2 py-1"
              verticalDividers={false}
              dataName="Instructional Plan"
              emptyStateIcon={<Icon icon="strand" />}
              data={data?.getCohortStrandStandards ?? []}
              className="min-h-0 rounded-t-none border-none"
              showHeaders={!!data?.getCohortStrandStandards.length}
              columns={getCohortStrandStandardsColumns(openDeleteModal)}
            />
          </Card>

          <AddCohortStrandStandard
            cohortId={id}
            cohortEndDate={endDate}
            cohortStartDate={startDate}
            show={showAddModal && !readOnly}
            refetchQueries={[
              GET_COHORT_INSTRUCTIONAL_PLAN_CARD,
              ...REFETCH_QUERIES,
            ]}
            closeModal={() => setShowAddModal(false)}
          />

          {period && (
            <DeleteCohortStrandStandards
              input={period}
              show={showDeleteModal && !readOnly}
              refetchQueries={[
                GET_COHORT_INSTRUCTIONAL_PLAN_CARD,
                ...REFETCH_QUERIES,
              ]}
              closeModal={() => closeModal()}
            />
          )}
        </>
      ) : null}
    </>
  );
}

const getCohortStrandStandardsColumns = (
  openDeleteModal: (
    item: CohortInstructionalPlanCard_CohortStrandStandardFragment
  ) => void
): Column<CohortInstructionalPlanCard_CohortStrandStandardFragment>[] => [
  {
    Header: () => (
      <div className="absolute top-0 left-0 w-full h-full flex flex-center">
        Period
      </div>
    ),
    accessor: "fromDate",
    width: 120,
    Cell: ({
      row: {
        original: { fromDate, toDate },
      },
    }: CellProps<CohortInstructionalPlanCard_CohortStrandStandardFragment>) => (
      <div className="flex flex-col text-center items-center justify-center">
        <span>{getNormalizedShortReadableDate(new Date(fromDate))}</span>
        <span>-</span>
        <span>{getNormalizedShortReadableDate(new Date(toDate))}</span>
      </div>
    ),
  },
  {
    Header: () => (
      <div className="w-full flex flex-center text-center pl-[10px]">
        Standards/Strands
      </div>
    ),
    accessor: "standards",
    Cell: ({
      row: { original },
    }: CellProps<CohortInstructionalPlanCard_CohortStrandStandardFragment>) => {
      return (
        <div className="w-full flex flex-1 justify-center">
          <StandardsStrandsList
            item={original}
            openEditModal={openDeleteModal}
          />
        </div>
      );
    },
  },
];
