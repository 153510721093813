import {
  CohortAssignmentSubject,
  CohortAssignmentSubSubject,
  GradeLevel,
  SubjectGradeLevels,
} from "@generated/graphql";
import { makeCacheKey } from "@utils/strings";
import { sortBy } from "lodash";
import { SubjectGradeLevelsMap } from "./types";

export const getSubjectGradeBadgesFromEvents = (cohort: {
  instructionLevel: GradeLevel;
  events: {
    cohortSubject: CohortAssignmentSubject;
    cohortSubSubject: CohortAssignmentSubSubject;
  }[];
}): SubjectGradeLevels[] => {
  const subjectMap: SubjectGradeLevelsMap = new Map();

  cohort.events.forEach(({ cohortSubject, cohortSubSubject }) => {
    const key = makeCacheKey(cohortSubject, cohortSubSubject || "none");

    if (!subjectMap.has(key))
      subjectMap.set(key, {
        subject: cohortSubject,
        subSubject: cohortSubSubject,
        gradeLevels: new Set(),
      });

    subjectMap.get(key)!.gradeLevels.add(cohort.instructionLevel);
  });

  return sortBy(Array.from(subjectMap.values()), "subject").map(
    ({ subject, subSubject, gradeLevels }) => ({
      subject,
      subSubject,
      gradeLevels: Array.from(gradeLevels),
    })
  );
};
