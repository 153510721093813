import { gql } from "@apollo/client";
import {
  AccountStatus,
  CohortTeachersCard_CohortFragment,
} from "@generated/graphql";
import { SpoofLoginButton } from "@shared/SpoofLoginButton";
import { Routes } from "@utils/routes";
import { EditCohortModal } from "components/cohorts/modals/AddEditModals";
import {
  EmptyStateContainer,
  Icon,
  Link,
  SubjectBadge,
} from "components/shared";
import {
  Card,
  CardDetailsRow,
  HeaderColors,
  getEditModalOpenHeader,
} from "components/shared/Card";
import { compact } from "lodash";
import { useMemo, useState } from "react";

CohortTeachersCard.fragments = {
  cohort: gql`
    fragment CohortTeachersCard_Cohort on Cohort {
      id
      engagementId
      events {
        teacherAssignedId
      }
      staffAssignments {
        user {
          id
          email
          fullName
          pronouns
          accountRole
          accountStatus
        }
        cohortSubject
        cohortAssignmentRole
      }
    }
  `,
};

type Props = {
  className?: string;
  cohort: CohortTeachersCard_CohortFragment;
};

export function CohortTeachersCard({ className, cohort }: Props) {
  const { staffAssignments, events } = cohort;
  const [showModal, setShowModal] = useState(false);
  const eventAssigneeIds = compact(events.map((e) => e.teacherAssignedId));

  const activeStaff = staffAssignments.filter(
    ({ user: { accountStatus } }) => accountStatus !== AccountStatus.Inactive
  );

  const teacherRows: CardDetailsRow[] = useMemo(
    () =>
      activeStaff.map(({ cohortSubject, user }) => [
        <div key={0} className="flex gap-2">
          <Link route={Routes.user.details} routeProps={[user.id]}>
            {user.fullName}
          </Link>
          {!eventAssigneeIds.includes(user.id) && (
            <span className="text-xs text-rose-500">(Unassigned)</span>
          )}
        </div>,
        <div
          key={0}
          className="flex flex-row flex-wrap-reverse gap-2 justify-end"
        >
          <SubjectBadge subject={cohortSubject} />
          <SpoofLoginButton user={user} />
        </div>,
      ]) as CardDetailsRow[],
    [activeStaff, eventAssigneeIds]
  );

  return (
    <>
      <Card
        icon="teacher"
        rows={teacherRows}
        className={className}
        headerOverlayColor={HeaderColors.Emerald}
        header={getEditModalOpenHeader("Teachers", () => setShowModal(true))}
      >
        {activeStaff.length < 1 && (
          <EmptyStateContainer
            className="border-0 border-gray-200 bg-none"
            icon={<Icon icon="teacher" size={8} />}
            dataName="Teachers"
          />
        )}
      </Card>

      <EditCohortModal
        show={showModal}
        cohortId={cohort.id}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
