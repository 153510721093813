import { gql, useMutation } from "@apollo/client";
import {
  CohortCoursesCard_CohortFragment,
  UpdateCohortCurriculumCourseRequiredMutation,
  UpdateCohortCurriculumCourseRequiredMutationVariables,
} from "@generated/graphql";
import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import { ToggleButton, triggerSuccessToast } from "components/shared";
import { Card, CardDetailsRow, HeaderColors } from "components/shared/Card";
import { OptionsArrayContainer } from "components/shared/Inputs/OptionsArrayContainer";
import { useEffect, useState } from "react";
import { CohortCurriculumCourseModal } from "./components/CohortCurriculumCourseModal";

CohortCoursesCard.fragments = {
  cohort: gql`
    fragment CohortCoursesCard_Cohort on Cohort {
      id
      curriculumCourseRequired
      talentLMSCourses {
        id
        name
        courseId
      }
    }
  `,
};

const EDIT_COHORT_CURRICULUM_COURSE_MUTATION = gql`
  mutation UpdateCohortCurriculumCourseRequired($input: EditCohortInput!) {
    editCohort(input: $input) {
      id
      curriculumCourseRequired
    }
  }
`;

type Props = {
  cohort: CohortCoursesCard_CohortFragment;
  refetch: () => void;
};

export function CohortCoursesCard({ cohort, refetch }: Props) {
  const { id, talentLMSCourses } = cohort;
  const initReq = cohort.curriculumCourseRequired;
  const [coursesRequired, setCoursesRequired] = useState<boolean>(initReq);
  const [showCurriculumCourseModal, setShowCurriculumCourseModal] =
    useState(false);

  const [editCohortCoursesRequired] = useMutation<
    UpdateCohortCurriculumCourseRequiredMutation,
    UpdateCohortCurriculumCourseRequiredMutationVariables
  >(EDIT_COHORT_CURRICULUM_COURSE_MUTATION, {
    onError: (error) => {
      setCoursesRequired(initReq);
      triggerSuccessToast({
        message: `Could not update Curriculum Courses required`,
        log: error,
      });
    },
    onCompleted: (data) =>
      triggerSuccessToast({
        message: `Curriculum Courses are ${
          data.editCohort.curriculumCourseRequired ? "now" : "not"
        } required`,
      }),
  });

  useEffect(() => {
    if (coursesRequired !== initReq) {
      editCohortCoursesRequired({
        variables: { input: { id, curriculumCourseRequired: coursesRequired } },
      });
    }
  }, [coursesRequired, editCohortCoursesRequired, id, initReq]);

  return (
    <>
      <Card
        icon="course"
        header="PD Curriculum Courses"
        rowStyles={[{ minWidth: "225px", width: "225px" }, {}]}
        headerOverlayColor={HeaderColors.Orange}
        rows={
          [
            [
              <p key={0}>Curriculum Courses Required</p>,
              <div key={0} className="flex items-center w-full justify-end">
                <ToggleButton
                  toggleState={coursesRequired}
                  handleToggle={(value) => setCoursesRequired(value)}
                />
              </div>,
            ],
            initReq && [
              <p key={0} className="pr-2">
                Curriculum Courses
              </p>,
              <OptionsArrayContainer
                key={0}
                hasItems={talentLMSCourses.length > 0}
                minHeight="min-h-[33px]"
                noItemsText="Select Courses..."
                noItemsClassName="justify-start!"
                onClick={() => setShowCurriculumCourseModal(true)}
                items={talentLMSCourses.map((course, index) => (
                  <span
                    key={index}
                    className={optionsArrayContainerItemStyle("edit")}
                  >
                    {course.name}
                  </span>
                ))}
              />,
            ],
          ].filter(Boolean) as CardDetailsRow[]
        }
      />

      <CohortCurriculumCourseModal
        cohort={cohort}
        show={showCurriculumCourseModal}
        refetch={refetch}
        onClose={() => setShowCurriculumCourseModal(false)}
      />
    </>
  );
}
