import { VideoProvider } from "@generated/graphql";
import { JoinMeetingButton } from "@shared/JoinMeetingButton";
import {
  Button,
  Icon,
  MinimalCopyButton,
  QrCodeImage,
  Tooltip,
} from "components/shared";
import { useState } from "react";
import { MSTeamsMeetingRegenerationModal } from "./MSTeamsMeetingRegenerationModal";

type Props = {
  cohortId: string;
  meetingLink: string;
  meetingLinkName: string;
  isZoomHostLink: boolean;
  videoProvider: VideoProvider;
};

export const RoomMeetingLink = ({
  cohortId,
  meetingLink,
  videoProvider,
  meetingLinkName,
  isZoomHostLink = false,
}: Props) => {
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const isMicrosoftTeams = videoProvider === VideoProvider.MicrosoftTeams;

  return (
    <>
      <div className="flex items-center gap-3 cursor-pointer">
        <JoinMeetingButton isHost={isZoomHostLink} cohortId={cohortId}>
          <div className="text-blue-500 hover:underline">{meetingLinkName}</div>
        </JoinMeetingButton>

        <MinimalCopyButton iconSize={4} copyValue={meetingLink} />

        <Tooltip
          content={<QrCodeImage text={meetingLink} width={125} />}
          className="flex flex-center cursor-pointer! text-xs text-gray-500"
          tooltipProps={{
            style: {
              borderRadius: "12px",
              padding: "12px 12px",
              backgroundColor: "#e5e7eb",
            },
          }}
        >
          <Icon icon="qrCode" size={3} color="inherit" button />
          QR Code
        </Tooltip>

        {isMicrosoftTeams && (
          <Button height="xs" onClick={() => setShowRegenerateModal(true)}>
            <Icon size={3} color="text-white" button icon="regenerate" />
            Regenerate Meeting
          </Button>
        )}
      </div>

      <MSTeamsMeetingRegenerationModal
        cohortId={cohortId}
        show={showRegenerateModal}
        onClose={() => setShowRegenerateModal(false)}
      />
    </>
  );
};
