import { SpecialDayType } from "./types";

export const SpecialDayTitleWidths: Record<SpecialDayType, number> = {
  [SpecialDayType.USHoliday]: 135,
  [SpecialDayType.Cancelled]: 215,
  [SpecialDayType.NoTutoring]: 170,
  [SpecialDayType.CohortNoShow]: 200,
  [SpecialDayType.CohortAssessment]: 225,
  [SpecialDayType.EngagementNoShow]: 242,
  [SpecialDayType.EngagementAssessment]: 260,
};
