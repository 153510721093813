import { Icon } from "@shared/Icon";
import { Tooltip } from "@shared/Tooltip";
import { SpecialDayType } from "./types";

type Props = { specialDayType: SpecialDayType };

export const SpecialDaysTooltip = ({ specialDayType }: Props) => (
  <Tooltip
    tooltipProps={{ place: "bottom-start" }}
    className="flex cursor-pointer items-start gap-[6px] w-fit"
    content={
      <div className="flex text-sm text-center min-w-[200px] max-w-[500px]">
        {SpecialDayTypeDetails[specialDayType]}
      </div>
    }
  >
    <div className="flex items-center gap-2 w-full">
      {specialDayType}
      <Icon icon="info" size={4} />
    </div>
  </Tooltip>
);

const SpecialDayTypeDetails: Record<SpecialDayType, string> = {
  [SpecialDayType.NoTutoring]:
    "School or district specific days where tutoring is not expected to take place. TTs/IS are not expected to be present and are not paid for these days.",
  [SpecialDayType.USHoliday]:
    "These are National holidays where tutoring is not expected to take place. TTs/IS are not expected to be present and are not paid for these days.",
  [SpecialDayType.CohortNoShow]:
    "Student attendance for cohort no-show days will be ignored in attendance calculations. TT/IS are still paid for these days.",
  [SpecialDayType.Cancelled]: "List of days with cancelled sessions.",
  [SpecialDayType.CohortAssessment]:
    "We do not expect students to attend on these days due to them taking assessments in person, so grading will be ignored on these days. TT/IS are expected to be present and are paid for these days.",
  [SpecialDayType.EngagementNoShow]:
    "Student attendance for engagement no-show days will be ignored in attendance calculations. TT/IS are still paid for these days.",
  [SpecialDayType.EngagementAssessment]:
    "We do not expect students to attend on these days due to them taking assessments in person, so grading will be ignored on these days. TT/IS are expected to be present and are paid for these days.",
};
