import { useAuth } from "@contexts/AuthProvider";
import { AccountRole, SetSpoofUser_UserFragment } from "@generated/graphql";
import clsx from "clsx";
import { Icon } from "./Icon";
import { Tooltip } from "./Tooltip";

type Props = {
  className?: string;
  user: SetSpoofUser_UserFragment;
};

export const SpoofLoginButton = ({ user, className }: Props) => {
  const { loginAsSpoofUser } = useAuth();
  const isAdmin = user.accountRole === AccountRole.Admin;

  return (
    <Tooltip
      content="Login as this user"
      tooltipProps={{ place: "left", delayShow: 200 }}
      disabled={isAdmin}
    >
      <div
        className={clsx(
          "flex flex-center rounded-md w-9 h-5 cursor-pointer",
          isAdmin
            ? "opacity-80 bg-gray-500"
            : "bg-violet-600 hover:bg-violet-800",
          className
        )}
        onClick={() => loginAsSpoofUser(user)}
      >
        <Icon icon="ghost" size={4} color="text-white" />
      </div>
    </Tooltip>
  );
};
