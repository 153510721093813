import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@contexts/AuthProvider";
import { useUIState } from "@contexts/UIStateProvider";
import {
  CohortDetailsTabEditCohortNotesMutation,
  CohortDetailsTabEditCohortNotesMutationVariables,
  CohortNotesCard_CohortFragment,
} from "@generated/graphql";
import { CreatorUpdaterLabel } from "components/shared";
import {
  Card,
  CardDetailsRow,
  getCollapseHeader,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { DynamicTextArea } from "components/shared/Inputs/DynamicTextArea";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

CohortNotesCard.fragments = {
  cohort: gql`
    fragment CohortNotesCard_Cohort on Cohort {
      id
      adminNotes
      instructionNotes
    }
  `,
};

const EDIT_COHORT_NOTES_MUTATION = gql`
  mutation CohortDetailsTabEditCohortNotes($input: EditCohortInput!) {
    editCohort(input: $input) {
      id
      adminNotes
      instructionNotes
      updatedAt
      updatedBy {
        ...CreatorUpdaterLabel_User
      }
    }
  }
  ${CreatorUpdaterLabel.fragments.user}
`;

type Props = {
  className?: string;
  readOnly?: boolean;
  heightMode?: HeightMode;
  cohort: CohortNotesCard_CohortFragment;
};

export function CohortNotesCard({
  cohort,
  className,
  readOnly = false,
  heightMode = HeightMode.Fit,
}: Props) {
  let hasNotes = false;
  const cohortIdRef = useRef("");
  const { isTeacher } = useAuth();
  const { storeBoolean } = useUIState();
  const { instructionNotes, adminNotes } = cohort;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const cohortNotesKey = `CohortNotesCard:${cohort.id}`;
  hasNotes = Boolean(instructionNotes || (!isTeacher && adminNotes));
  const [draftAdminNotes, setDraftAdminNotes] = useState(adminNotes);

  const [draftInstructionNotes, setDraftInstructionNotes] =
    useState(instructionNotes);

  const [debouncedDraftAdminNotes, { isPending: adminNotesIsPending }] =
    useDebounce(draftAdminNotes, 1000);

  const [
    debouncedDraftInstructionNotes,
    { isPending: instructionNotesIsPending },
  ] = useDebounce(draftInstructionNotes, 1000);

  useEffect(() => {
    if (cohortIdRef.current !== cohort.id) {
      setIsCollapsed(!hasNotes);
      setDraftAdminNotes(adminNotes);
      setDraftInstructionNotes(instructionNotes);
      cohortIdRef.current = cohort.id;
    }
  }, [cohort, adminNotes, instructionNotes, hasNotes]);

  const [editCohortNotes, { loading: mutationLoading }] = useMutation<
    CohortDetailsTabEditCohortNotesMutation,
    CohortDetailsTabEditCohortNotesMutationVariables
  >(EDIT_COHORT_NOTES_MUTATION);

  useEffect(() => {
    const trimmedDebouncedDraftAdminNotes = debouncedDraftAdminNotes?.trim();
    const trimmedDebouncedDraftInstructionNotes =
      debouncedDraftInstructionNotes?.trim();

    if (
      trimmedDebouncedDraftAdminNotes != cohort.adminNotes ||
      trimmedDebouncedDraftInstructionNotes != cohort.instructionNotes
    ) {
      editCohortNotes({
        variables: {
          input: {
            id: cohort.id,
            adminNotes: trimmedDebouncedDraftAdminNotes || null,
            instructionNotes: trimmedDebouncedDraftInstructionNotes || null,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedDraftAdminNotes,
    debouncedDraftInstructionNotes,
    // Only trigger this effect when the debounced values change.
  ]);

  useEffect(() => {
    storeBoolean(cohortNotesKey, !readOnly || hasNotes);
  }, [readOnly, hasNotes, storeBoolean, cohortNotesKey]);

  return !readOnly || hasNotes ? (
    <Card
      icon="note"
      id={cohortNotesKey}
      className={className}
      heightMode={heightMode}
      isCollapsed={isCollapsed}
      headerOverlayColor={HeaderColors.Sky}
      header={
        isTeacher
          ? "Instructional Notes"
          : getCollapseHeader("Notes", isCollapsed, setIsCollapsed)
      }
      rows={
        isCollapsed
          ? []
          : ([
              [
                "Admin Notes",
                <DynamicTextArea
                  key={0}
                  rows={3}
                  readOnly={readOnly}
                  value={draftAdminNotes}
                  placeholder="Add admin notes..."
                  mutationLoading={mutationLoading}
                  debouncePending={adminNotesIsPending()}
                  onChange={(value) => setDraftAdminNotes(value)}
                  textAreaClassName="min-h-[38px] max-h-[150px]"
                />,
              ],
              [
                "Instructional Notes",
                <DynamicTextArea
                  key={0}
                  rows={3}
                  readOnly={readOnly}
                  value={draftInstructionNotes}
                  mutationLoading={mutationLoading}
                  placeholder="Add instruction notes..."
                  debouncePending={instructionNotesIsPending()}
                  onChange={(value) => setDraftInstructionNotes(value)}
                  textAreaClassName="min-h-[38px] max-h-[150px]"
                />,
              ],
            ].filter(
              ([name]) => !isTeacher || name !== "Admin Notes"
            ) as CardDetailsRow[])
      }
    />
  ) : null;
}
