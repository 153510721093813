import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { format } from "date-fns";
import { OptionsArrayContainer } from "../Inputs/OptionsArrayContainer";
import { Tooltip } from "../Tooltip";

type Props = {
  maxHeight?: string;
  specialDays: Date[];
  noDaysMessage: string;
  onClick?: () => void;
  withItemTooltip?: { date: Date; content: string }[];
  placeholderMode?: boolean;
  className?: string;
};

export const SpecialDaysScrollContainer = ({
  maxHeight,
  specialDays,
  noDaysMessage,
  withItemTooltip,
  onClick,
  placeholderMode = false,
  className,
}: Props) => (
  <OptionsArrayContainer
    className={className}
    onClick={onClick}
    minHeight="min-h-[33px]"
    maxHeight={maxHeight}
    noItemsText={noDaysMessage}
    hasItems={specialDays.length > 0}
    items={specialDays
      .sort((a, b) => a.getTime() - b.getTime())
      .map((day, index) => {
        const formattedDate = format(day, "MMM d, yyyy");
        const tooltipContent = withItemTooltip?.find(
          (item) => item.date.getTime() === day.getTime()
        )?.content;

        return (
          <span
            key={index}
            className={clsx(
              "justify-center w-[100px]!",
              optionsArrayContainerItemStyle(
                placeholderMode ? "placeholder" : "edit"
              )
            )}
          >
            {withItemTooltip ? (
              <Tooltip
                content={tooltipContent}
                contentClassName="text-sm text-center text-white max-w-[30ch] whitespace-normal"
              >
                {formattedDate}
              </Tooltip>
            ) : (
              formattedDate
            )}
          </span>
        );
      })}
  />
);
