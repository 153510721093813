import { CONTEXT_MENU_ID, Icon, Table } from "components/shared";
import { CellProps, Column } from "react-table";

type Props = {
  data: { id: string; key: string; description: string }[];
  handleRemoveItem: (index: number) => void;
  dataName: string;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
};

export const TableStrandStandard = ({
  data,
  handleRemoveItem,
  dataName,
  emptyStateTitle,
  emptyStateSubtitle,
}: Props) => {
  return (
    <Table
      dataName={`${dataName}`}
      data={data}
      pageSize={100}
      columns={itemColumns(handleRemoveItem)}
      showHeaders={false}
      showPagination={false}
      className="border border-gray-200 rounded-lg"
      emptyStateIcon={<Icon icon="strand" />}
      emptyStateTitle={emptyStateTitle}
      emptyStateSubtitle={emptyStateSubtitle}
    />
  );
};

const itemColumns = (
  handleRemoveItem: (index: number) => void
): Column<{
  id: string;
  key: string;
  description: string;
}>[] => [
  {
    Header: "Key",
    accessor: "key",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: " ",
    id: CONTEXT_MENU_ID,
    Cell: ({
      row,
    }: CellProps<{
      id: string;
      key: string;
      description: string;
    }>) => (
      <div
        className="flex justify-center group items-center cursor-pointer h-full w-8"
        onClick={() => handleRemoveItem(row.index)}
      >
        <Icon
          icon="trash"
          size={5}
          color="text-slate-700 group-hover:text-rose-600"
        />
      </div>
    ),
  },
];
