import { gql } from "@apollo/client";
import { StandardsStrandsList_CohortStrandStandardFragment } from "@generated/graphql";
import { Icon } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { LinkType } from "../types";
import { StandardStrandListItems } from "./StandardStrandListItems";

const STANDARDS_STRANDS_LIST_PAGE_QUERY_NAME = gql`
  fragment StandardsStrandsList_CohortStrandStandardDetail on CohortStrandStandardDetail {
    id
    key
    description
    url
    notes
  }
`;

StandardsStrandsList.fragments = {
  cohortStandardStrand: gql`
    fragment StandardsStrandsList_CohortStrandStandard on CohortStrandStandard {
      id
      fromDate
      toDate
      cohortId
      strands {
        id
        key
        description
        url
        notes
        ...StandardsStrandsList_CohortStrandStandardDetail
      }
      standards {
        id
        key
        description
        url
        notes
        ...StandardsStrandsList_CohortStrandStandardDetail
      }
    }
    ${STANDARDS_STRANDS_LIST_PAGE_QUERY_NAME}
  `,
};

type Props = {
  item: StandardsStrandsList_CohortStrandStandardFragment;
  openEditModal: (
    item: StandardsStrandsList_CohortStrandStandardFragment
  ) => void;
};

export function StandardsStrandsList({ item, openEditModal }: Props) {
  const { isAdmin } = useAuth();
  return (
    <div className="relative w-full">
      <div className="flex flex-row pr-4 w-full">
        {isAdmin && (
          <div
            className="absolute flex flex-center right-1 top-1 z-10 p-1 bg-red-500/20 hover:bg-red-500 rounded-full cursor-pointer"
            onClick={() => openEditModal(item)}
          >
            <Icon icon="trash" size={4} color="text-white" />
          </div>
        )}

        <div className="flex flex-wrap p-1 w-full">
          <StandardStrandListItems
            items={item.strands}
            type={LinkType.Strand}
          />
          <StandardStrandListItems
            items={item.standards}
            type={LinkType.Standard}
          />
        </div>
      </div>
    </div>
  );
}
